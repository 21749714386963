$( '#mega-menu-main-menu > .mega-menu-item-has-children li.mega-current-page-ancestor' ).each( ( index, el ) => {
  $( el ).parents( '.mega-menu-item' ).addClass( 'mega-current-menu-ancestor' );
} );

//Add Class on Scroll
const header = $( 'body' );
let position = window.scrollY;
const scrollClass = () => {
  let scroll = window.scrollY;
  if ( scroll >= 1 ) {
    header.addClass( 'page-scrolled' );
    if ( scroll > position ) {
      header.addClass( 'scroll-down' );
    } else {
      header.removeClass( 'scroll-down' );
    }
    position = scroll;
  } else {
    header.removeClass( 'page-scrolled' );
  }

};
$( window ).on( 'load scroll', scrollClass );
