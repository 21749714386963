class PressInnerToc {
  constructor( toc ) {
    this.toc = toc;
    this.section = toc.closest( '.bs-section--press-inner-content' );
    this.links = toc.find( '.bs-div ul li a' );
  }

  activeLink( link ) {
    this.links.filter( '.active' ).removeClass( 'active' );
    link.addClass( 'active' );
  }

  scrollToTopHandler() {
    const button = $( '<button class="goto-top"></button>' );
    $( 'main' ).append( button );
    const observer = new IntersectionObserver(
      entries => {
        if ( entries[0].isIntersecting ) {
          button.addClass( 'show' );
        } else {
          button.removeClass( 'show' );
        }
      },
      {
        root: null,
        rootMargin: '-30% 0px -30% 0px',
      }
    );
    observer.observe( this.section.get( 0 ) );
    button.on( 'click', () => {
      this.links.addClass( 'locked' );
      const link = this.links.eq( 0 );
      const targetId = link.attr( 'href' );
      const targetHeading = this.section.find( targetId );
      this.activeLink( link );
      this.scrollTo( targetHeading.offset().top - 135, () => {
        this.links.removeClass( 'locked' );
      } );
    } );
  }

  scrollTo( offset, callback ) {
    const fixedOffset = offset.toFixed();
    const onScroll = () => {
      if ( window.scrollY.toFixed() === fixedOffset ) {
        window.removeEventListener( 'scroll', onScroll );
        callback();
      }
    };

    window.addEventListener( 'scroll', onScroll );
    onScroll();
    window.scrollTo( {
      top: offset,
      behavior: 'smooth',
    } );
  }

  linkClicksHandler() {
    this.links.on( 'click', e => {
      e.preventDefault();
      this.links.addClass( 'locked' );
      const link = $( e.target ).closest( 'a' );
      const targetId = link.attr( 'href' );
      const targetHeading = this.section.find( targetId );
      this.activeLink( link );
      this.scrollTo( targetHeading.offset().top - 135, () => {
        this.links.removeClass( 'locked' );
      } );
      setTimeout( () => {
        this.links.removeClass( 'locked' );
      }, 600 );
    } );
  }

  observerHandler() {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach( entry => {
          if ( entry.isIntersecting ) {
            const targetHeading = $( entry.target );
            const link = this.links.filter( '[href="#' + targetHeading.attr( 'id' ) + '"]' );
            if ( !link.hasClass( 'locked' ) ) this.activeLink( link );
          }
        } );
      },
      {
        root: null,
        rootMargin: '0px 0px -70% 0px',
      }
    );
    this.links.each( ( i, link ) => {
      const targetId = $( link ).attr( 'href' );
      const targetHeading = this.section.find( targetId );
      observer.observe( targetHeading.get( 0 ) );
    } );
  }

  init() {
    this.linkClicksHandler();
    this.observerHandler();
    this.scrollToTopHandler();
    this.activeLink( this.links.eq( 0 ) );
  }
}

$( $ => {
  $( '.bs-div--press-inner-toc' ).each( ( i, toc ) => {
    const pressInnerToc = new PressInnerToc( $( toc ) );
    pressInnerToc.init();
  } );
} );
