$( () => {
  $( '.sf-form-field input, .sf-form-field textarea' )
    .focus( function () {
      $( this ).parent().addClass( 'active' );
    } )
    .focusout( function () {
      if ( !$( this ).val() ) {
        $( this ).parent().removeClass( 'active' );
      } else {
        $( this ).parent().addClass( 'active' );
      }
    } );
} );
