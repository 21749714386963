( $ => {
  const $commonLogoSliders = $( '.bs-section--common-vertical-logo-slider' );

  if( !$commonLogoSliders || $commonLogoSliders.length === 0 ) {
    return;
  }

  // get all logos in the element
  const getLogosItem = slideParent => {
    return slideParent.find( 'ul li' );
  };

  // generate animation speed
  const generateAnimationSpeed = elem => {
    const elementDataSpeed = $( elem ).attr( 'data-speed' );
    return elementDataSpeed / 1000;
  };

  const changeAnimationDirection = ( i, dom ) => {
    if ( i % 2 !== 0 ) {
      $( dom ).attr( 'data-direction', 'right' );
    }
  };

  const logoPlayHandler = slidersData => {
    const sliderParent = slidersData.slider.parent[0],
      sliderLogos = slidersData.slider.logos;

    const desktopLogoCount = sliderLogos.length,
      animateSpeed = generateAnimationSpeed( sliderParent );

    // set slider animate class if logo count more than 4
    const sliderStartClass = desktopLogoCount > 5 ? 'start-marquee' : 'stop-playing';

    $( sliderParent )
      .find( 'ul' )
      .addClass( sliderStartClass )
      .html( '' )
      .append( sliderLogos.clone() )
      .append( sliderLogos.clone() )
      .append( sliderLogos.clone() )
      .append( sliderLogos.clone() )
      .append( sliderLogos.clone() )
      .append( sliderLogos.clone() )
      .css( 'animation-duration', `${animateSpeed}s` );
  };

  // looping all logo slider sections
  $commonLogoSliders.each( ( ind, ele ) => {
    const $logoSliderSingleSection = $( ele );
    if ( $logoSliderSingleSection.length !== 0 ) {
      const $logoSliders = $logoSliderSingleSection.find( '.clients-slider-wrapper' );
      // collect slide logos
      $logoSliders.each( ( index, sliderEl ) => {
        // Set Direction
        changeAnimationDirection( index, sliderEl );

        const slideLogos = getLogosItem( $( sliderEl ) );
        // create sliders object for modification
        const slidersData = {
          slider: {
            parent: $( sliderEl ),
            logos: slideLogos,
          },
        };
        logoPlayHandler( slidersData );
      } );
    }
  } );
} )( jQuery );
