$( $ => {
  const progressContents = $( '.bs-section--progress-bar-content' );
  if ( progressContents.length === 0 ) return;

  const header = $( 'header' );
  let progressInner = null;

  const readyDOM = () => {
    header.append( `
        <div class="content-progress-bar">
            <div class="content-progress-bar__inner"></div>
        </div>
    ` );
    progressInner = header.find( '.content-progress-bar__inner' );
  };

  const updateProgress = () => {
    let totalHeight = 0;
    const offset = progressContents.eq( 0 ).offset().top;
    const windowHeight = $( window ).height();

    progressContents.each( ( i, content ) => ( totalHeight += $( content ).height() ) );

    const scrollPos = $( window ).scrollTop() + windowHeight - offset;

    console.log( '*****', offset, scrollPos, totalHeight );

    let precentage = ( scrollPos / totalHeight ) * 100;
    if( precentage < 0 ) precentage = 0;
    else if ( precentage > 100 ) precentage = 100;

    progressInner.css( 'width', `${precentage}%` );
  };

  readyDOM();
  updateProgress();
  $( window ).on( 'load scroll resize', updateProgress );
} );
