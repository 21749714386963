$( $ => {
  const linkElements = document.querySelectorAll(
    '.bs-section--careers-banner a:not([data-fancybox])'
  ); // all links in inner menu
  linkElements.forEach( ( elem, index ) => {
    if ( elem.parentNode.classList.contains( 'bs-media-element--careers-gptw' ) ) {
      return;
    }
    linkElements[index].addEventListener( 'click', e => {
      e.preventDefault();
      const clickedSectionId = linkElements[index].attributes.href.value.replace( '#', '' );

      const scrollSection = document.querySelectorAll( '[id="' + clickedSectionId + '"]' )[0].offsetTop;
      $( 'html, body' ).animate(
        {
          scrollTop: scrollSection - 120,
        },
        400
      );
    } );
  } );
} );
