require( 'slick-carousel-latest' );
$( () => {
  const $tabSliders = $( '.bs-tab-slider' );

  if ( !$tabSliders || $tabSliders.length === 0 ) {
    return;
  }

  $tabSliders.each( ( i, ele ) => {
    const $tabSlider = $( ele );
    const $slider = $tabSlider.find( '.slick-slider' );
    const trigger = $tabSlider.find( '.bs-slider-tabs .slick-slide-wrapper h3' );

    trigger.on( 'click', () => {
      if ( $slider.hasClass( 'slick-initialized' ) ) {
        $slider.slick( 'slickPause' ).addClass( 'stop-slider' );
      }
    } );
  } );
} );
