$( ( $ ) => {
  const appendTitles = () => {
    const columnEvents = $( '.bs-column--events-title' );
    if ( columnEvents.length === 0 ) return;
    const parentRow = columnEvents.parents( '.bs-row' );
    parentRow.each( ( index, element ) => {
      element = $( element );
      const titleColumn = element.find( '.bs-column--events-title' ).clone();
      const postParent = element.find( '.bs-posts__list' );
      if (
        postParent.length &&
        !postParent.children( '.bs-column--events-title' ).length
      ) {
        postParent.prepend( titleColumn );
      }
    } );
  };

  appendTitles();

  $( document ).on( 'facetwp-loaded', () => {
    appendTitles();
  } );
} );
