import { LottieAnimator } from '~bergBlockPath/lottie-animator/js/core-event-handler';

$( ( $ ) => {
  $( () => {
    const section = $( '.bs-section--home-breakthrough-platform' ),
      lotties = section.find( '.bs-lottie-animator' ),
      card = section.find( '.card' ),
      lotty = [],
      windowWidth = $( window ).width();
    let lottieObsPlay = true;
    
    
    lotties.each( ( index, item ) => {
      lotty.push( new LottieAnimator( item ) );
      lotty[index].init();
    } );
   
    const lottieAnimation = () => {
      if ( windowWidth > 575 ) {
        const lottieCount = lotty.length;
        let loopIndex = 0;
        if( lottieObsPlay === true ) {
          setInterval( () => {
            if( loopIndex < lottieCount ) {
              lotty[loopIndex].lottieAnimation.goToAndStop( 0, true ); 
              lotty[loopIndex].lottieAnimation.play();
              loopIndex++;
            }else {
              loopIndex = 0;
            }
          },2000 );
        }

      } else {
        lotty[0].lottieAnimation.goToAndStop( 0, true ); 
        lotty[0].lottieAnimation.play();
        
        card.each( ( index, el ) => {
          $( el ).on( 'click', () => {
            setTimeout( () => {
              lotty[index].lottieAnimation.goToAndStop( 0, true ); 
              lotty[index].lottieAnimation.play();
            },1000 );
          } );
        } );
      }
    };
    
    const observer = new IntersectionObserver( ( entries ) => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          lottieAnimation();
          lottieObsPlay = false;
        }
      } );
    } );
    section.each( ( i, item ) => {
      observer.observe( item );
    } );
  } );
} );
