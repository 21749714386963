/* eslint-env jquery */
( $ => {
  $.fn.parallaxMove = function ( options ) {
    const settings = $.extend(
      {
        offset: 0,
        mobile: false,
        dir: 'top',
        headerFixed: false,
        twoWay: false,
        parentEl: 'section',
      },
      options
    );
    const currEl = this,
      wH = $( window ).height(),
      wW = $( window ).width(),
      hH = $( 'header' ).height(),
      parent = currEl.parents( `${settings.parentEl}` ),
      boundingBox = parent[0]?.getBoundingClientRect(),
      parentTop = boundingBox?.top,
      parentBottom = boundingBox?.bottom,
      speed = settings.headerFixed ? settings.offset / ( wH - hH ) : settings.offset / wH,
      inViewHeight = wH - parentTop,
      checkTwoWay = !settings.twoWay ? parentTop >= 100 : parentTop >= boundingBox?.height * -1,
      scrollPortion =
        settings.dir === 'top' || settings.dir === 'right'
          ? settings.offset - inViewHeight * speed
          : ( settings.offset - inViewHeight * speed ) * -1,
      disableParallax = wW < 768 && !settings.mobile ? true : false;
    if ( parentTop <= parseInt( wH + 100 ) && parentBottom >= 0 && checkTwoWay && !disableParallax ) {
      if ( settings.dir === 'top' ) {
        currEl
          .css( {
            transform: 'translate3d(0,' + scrollPortion + 'px,0)',
          } )
          .addClass( 'parallax-init' );
      } else if ( settings.dir === 'right' ) {
        currEl
          .css( {
            transform: 'translate3d(' + scrollPortion + 'px, 0, 0)',
          } )
          .addClass( 'parallax-init' );
      }
    } else if ( !settings.twoWay && parentTop <= 0 ) {
      currEl.not( '.parallax-init' ).css( {
        transform: 'translate3d(0, 0, 0)',
      } );
    } else if ( disableParallax ) {
      currEl[0].style.removeProperty( 'transform', '-webkit-transform' );
    }
    currEl.addClass( 'parallax-item' );
  };
} )( jQuery );

const mediaElement = $( '.bs-section--our-vision .bs-column.bs-column--common-wrapper .media-elements' );
const pressInnerBlockquotes = $( '.bs-div--press-inner-blockquote' );

$( window ).on( 'load scroll resize', () => {
  if ( $( '.bs-section--our-vision .bs-column.bs-column--common-wrapper' ).length ) {
    mediaElement.addClass( 'full-opacity' );
    if ( mediaElement.eq( 0 ) ) {
      mediaElement.eq( 0 ).parallaxMove( {
        offset: -50,
        headerFixed: true,
      } );
    }
    if ( mediaElement.eq( 1 ) ) {
      mediaElement.eq( 1 ).parallaxMove( {
        offset: 80,
        headerFixed: true,
      } );
    }
  }
  pressInnerBlockquotes.each( ( i, pressInnerBlockquote ) => {
    pressInnerBlockquote = $( pressInnerBlockquote );
    if ( pressInnerBlockquote.closest( '.slick-slide-wrapper' ).length > 0 ) return;
    const blockquote = pressInnerBlockquote.find( 'blockquote' );
    const image = pressInnerBlockquote.find( '.media-elements' );
    const commonOptions = { headerFixed: true, parentEl: '.bs-div' };
    blockquote.parallaxMove( {
      ...commonOptions,
      offset: 70,
    } );
    image.parallaxMove( {
      ...commonOptions,
      offset: -40,
    } );
  } );
} );
