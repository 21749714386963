$( $ => {
  const searchHeader = $( 'body.search' );

  if ( !searchHeader || searchHeader.length === 0 ) {
    return;
  }

  if ( searchHeader.hasClass( 'transparent-header' ) ) {
    searchHeader.removeClass( 'transparent-header' );
  }

} );