
( ( $ ) => {
  /* eslint-disable no-undef */
  $( document ).on( 'keyup', '.facetwp-type-search .facetwp-search', ( e ) => {
    const inputValue = e.currentTarget.value;
    if ( inputValue.length > 0 && inputValue.length < 2 ) {
      FWP.is_refresh = true;
      setTimeout( () => {
        FWP.is_refresh = false;
      }, 150 );
    }
  } );
  /* eslint-enable */
} )( jQuery );

/* eslint-disable no-dupe-else-if */
/* eslint-disable no-undef */
$( () => {
  const smoothscroll = () => {
    const speed = 500;
    const headerHeight = $( 'header' ).height() + 45;
    const innerMenuHeight = $( '#inner-menu' ).is( ':visible' )
      ? $( '#inner-menu' ).height()
      : 0;
    const facetTemplateTop = $( '.facetwp-type-search' );
    if ( $( '.facetwp-load-more' ).length == 0 ) {
      if ( facetTemplateTop.length ) {
        if ( FWP.enable_scroll == true ) {
          $( 'html, body' ).animate(
            {
              scrollTop:
								$( facetTemplateTop ).offset().top -
								( headerHeight + innerMenuHeight ),
            },
            speed
          );
        }
      }
    }
  };

  const mobilePaginationClassAdding = () => {
    if ( $( '.facetwp-facet-pagination' ).length > 0 ) {
      if ( $( window ).width() < 576 ) {
        const paginationElements = $(
          '.facetwp-facet-pagination .facetwp-page'
        );
        const dotsContent = '<a class="facetwp-page dots">…</a>';
        const activeIndex = $( '.facetwp-facet-pagination' )
          .find( '.active' )
          .index();
        const lastIndex = paginationElements.length - 1;
        paginationElements.each( ( i, e ) => {
          const element = $( e );
          if (
            ! (
              i === 0 ||
							i === 1 ||
							i === lastIndex - 1 ||
							i === lastIndex ||
							i === activeIndex - 1 ||
							i === activeIndex ||
							i === activeIndex + 1
            )
          )
            element.addClass( 'hide-mobile' );
          if ( i > 1 && i < activeIndex - 1 )
            element.addClass( 'left-items' );
          if ( i > activeIndex + 1 && i < lastIndex - 1 )
            element.addClass( 'right-items' );
        } );
        const leftHiddenContent = $(
          '.facetwp-facet-pagination .facetwp-page.hide-mobile.left-items'
        );
        const rightHiddenContent = $(
          '.facetwp-facet-pagination .facetwp-page.hide-mobile.right-items'
        );
        if (
          typeof leftHiddenContent !== 'undefined' &&
					leftHiddenContent.length > 0
        ) {
          leftHiddenContent.first().before( dotsContent );
          leftHiddenContent.remove();
        }
        if (
          typeof rightHiddenContent !== 'undefined' &&
					rightHiddenContent.length > 0
        ) {
          rightHiddenContent.last().after( dotsContent );
          rightHiddenContent.remove();
        }
      }
    }
  };

  const hideFiltersWhenEmpty = () => {
    const allFacetOptions = $( '.facetwp-type-fselect .fs-options' );
    if ( allFacetOptions.length > 0 ) {
      allFacetOptions.each( ( i, e ) => {
        const element = $( e );
        if ( element.find( '.fs-option' ).length === 0 ) {
          element.closest( '.bs-column' ).hide();
        } else {
          element.closest( '.bs-column' ).show();
        }
      } );
    }
  };

  function cleanFacetResult() {
    const searchSelections = $( '.facetwp-selections li' );
    searchSelections.each( function( i, el ) {
      const itemType = $( el ).attr( 'data-facet' );
      const itemValue = $( el ).find( '.facetwp-selection-value' ).text();

      if ( itemType == 'search' && itemValue.trim() == '' ) {
        $( '.facetwp-type-reset' ).hide();
        $( el ).hide();
      } else {
        $( '.facetwp-type-reset' ).show();
      }
    } );
  }

  $( document ).on( 'facetwp-refresh', () => {
    if ( FWP.soft_refresh === true ) {
      FWP.enable_scroll = true;
    } else {
      FWP.enable_scroll = false;
    }
  } );

  changeTitle();
  hideFiltersWhenEmpty();
  
  $( document ).on( 'facetwp-loaded', () => {
    changeTitle();

    if ( FWP.enable_scroll === true ) {
      smoothscroll();
    }
    setTimeout( () => {
      cleanFacetResult();
    }, 0 );
    mobilePaginationClassAdding();
    hideFiltersWhenEmpty();
  } );

  $( window ).on( 'load resize', () => {
    mobilePaginationClassAdding();
    hideFiltersWhenEmpty();
  } );
  cleanFacetResult();
} );

const changeTitle = () => {
  const searchFacet = 'search';
  const searchBox = $( '[data-name="' + searchFacet + '"] .facetwp-search' );

  if ( ! searchBox.next( 'i' ).length ) {
    $( '#upcoming-events' ).text( 'Events' );
  }

  if ( searchBox.val() === '' ) {
    $( '#upcoming-events' ).text( 'Upcoming Events' );
  }
};
