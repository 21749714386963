$( () => {
  const inView = new IntersectionObserver( ( entry ) => {
    if ( entry[0].isIntersecting ) {
      if( !$( entry[0].target ).hasClass( 'animate' ) ) {
        $( entry[0].target ).addClass( 'animate' );
      }
    }
  } );

  const sections = document.querySelectorAll( '.bs-section' );
  sections.forEach( ( element ) => {
    inView.observe( element );
  } );
} );
