$( () => {
  const $sliderSection = $( '.bs-section--making-truly-tab-slider' );
  if( $sliderSection.length === 0 ) return;

  const createAccordion = ( sliderSection ) => {
    sliderSection.each( ( index, element ) => {
      let $wrapper = $( element );
      let accordionItems = $wrapper.find( '.bs-slider-content .slick-slide-wrapper' );
      const slideSpeed = 900;

      //settings for first item
      accordionItems.eq( 0 ).show().addClass( 'active-in-mobile' );

      accordionItems.each( ( index, element ) => {
        //replace titles
        let title = $wrapper.find( '.bs-slider-tabs .slick-slide-wrapper' ).eq( index ).html();
        $( element ).prepend( title );

        //make accordion
        let sliderTitle = $( element ).find( 'h3' );
        sliderTitle.on( 'click', ( e ) => {
          let $this = $( e.currentTarget );
          let slideWindow = $this.next( '.bs-div' );

          if ( $this.parent().hasClass( 'active-in-mobile' ) ) {  
            slideWindow.slideUp( slideSpeed, function() {
              $this.parent().removeClass( 'active-in-mobile' );
            } );
            
          } 
          else {
            $wrapper.find( '.active-in-mobile' ).each( ( index, element ) => {
              $( element ).children( '.bs-div' ).slideUp( slideSpeed, function() {
                $( element ).removeClass( 'active-in-mobile' );
                $( 'html,body' ).scrollTop( $this.parent().offset().top - 80 );
              } );
            } );
            slideWindow.slideDown( slideSpeed );
            $this.parent().addClass( 'active-in-mobile' );
            
          }
        } );
      } );
    } );
  };

  const init = () =>{
    if ( $( window ).width() < 575 ) {
      createAccordion( $sliderSection );
    }
  };
  
  init();

  //animating arrow
  const aboutUsObserver = new IntersectionObserver( ( entries ) => {
    entries.forEach( entry => {
      if( entry.isIntersecting ) {
        $sliderSection.find( '.bs-div--slider-content' ).addClass( 'animated' );
        aboutUsObserver.disconnect();
      }
    } );
  }, {} );

  $sliderSection.find( '.bs-div--slider-counters' ).each( ( index, element ) => {
    aboutUsObserver.observe( element );
  } );

} );
