$( () => {
  const hamburgerTrigger = $( 'header .navbar__toggler' ),
    navDropDown = $( 'header .navbar__inner' ),
    menuLevel2 = navDropDown.find( 'li.mega-menu-item-has-children' ),
    body = $( 'body' ),
    mainMenu = navDropDown.find( '#mega-menu-main-menu > .mega-menu-item' ),
    search = navDropDown.find( '.desktop-search-trigger' ),
    closeSearch = navDropDown.find( '.search-icon.close-icon' );

  search.on( 'click', () => {
    body.addClass( 'open-search-popup' );
  } );

  closeSearch.on( 'click', () => {
    body.removeClass( 'open-search-popup' );
  } );

  hamburgerTrigger.on( 'click', e => {
    e.preventDefault();
    if ( navDropDown.hasClass( 'active' ) ) {
      menuLevel2.addClass( 'mega-toggle-on' );
      navDropDown.removeClass( 'active' ).find( '.navbar__collapse' ).removeClass( 'active' );
      body.removeClass( 'mega-menu__open' );
    } else {
      navDropDown.addClass( 'active' ).find( '.navbar__collapse' ).addClass( 'active' );
      body.addClass( 'mega-menu__open' );
    }
  } );

  //mobile close one menu on other expand
  $( 'header .mega-indicator' ).on( 'click', e => {
    let $link = $( e.target );
    let $parent = $link.parents( '.mega-menu-item' );
    if ( !$parent.hasClass( 'mega-toggle-on' ) ) {
      $parent.removeClass( 'mega-toggle-on' );
      $link.parent().attr( 'aria-expanded', false );
    } else {
      $( '.mega-toggle-on a' ).attr( 'aria-expanded', false );
      $( '.mega-toggle-on' ).removeClass( 'mega-toggle-on' );
      $parent.addClass( 'mega-toggle-on' );
      $link.parent().attr( 'aria-expanded', true );
    }
  } );

  mainMenu.each( ( _index, menu ) => {
    let hasActiveMenu = 0;
    const submenu = $( menu ).find( '> .mega-sub-menu' );
    const megaMenu = $( menu ).find(
      '.mega-menu-row .mega-sub-menu .mega-menu-column:first-child > .mega-sub-menu > .mega-menu-item'
    );
    let hasChild = $( menu ).hasClass( 'mega-menu-item-has-children' );
    let hasSubChild = $( menu ).hasClass( 'sub-menu-type-three' );

    // menu hover
    megaMenu.each( ( ind, element ) => {
      if ( $( element ).hasClass( 'mega-current-menu-ancestor' ) ) {
        hasActiveMenu = ind;
      }
      $( element ).on( 'mouseover', () => {
        $( megaMenu ).removeClass( 'hover-active' );
        $( element ).addClass( 'hover-active' );
      } );
    } );
    $( megaMenu[hasActiveMenu] ).addClass( 'hover-active' );

    // active default menu item when main menu close
    submenu.on( 'mouseleave', () => {
      if ( !submenu.hasClass( 'mega-toggle-on' ) ) {
        $( megaMenu ).removeClass( 'hover-active' );
        $( megaMenu[hasActiveMenu] ).addClass( 'hover-active' );
      }
    } );

    // dropdown height
    if ( $( window ).width() > 1199 && hasChild && !hasSubChild ) {
      const leftMenu = $( menu ).find( '.mega-menu-column:first-child > .mega-sub-menu' );
      const centerMenus = $( menu ).find( '.mega-menu-column:first-child > .mega-sub-menu > .mega-menu-item' );
      const megaRow = $( menu ).find( '.mega-menu-row' );
      let eachMenuHeight;

      centerMenus.each( ( i, e ) => {
        const eachSubMenu = $( e ).find( '> .mega-sub-menu' );
        eachSubMenu.css( 'cssText', 'display: block !important;' );
        const tempHeight = ( eachSubMenu.height() > leftMenu.height() ) ? eachSubMenu.height() : leftMenu.height();
        eachMenuHeight = ( eachMenuHeight > tempHeight ) ? eachMenuHeight : tempHeight;
        eachSubMenu.removeAttr( 'style' );
      } );
      megaRow.css( 'height', ( eachMenuHeight + 92 ) + 'px' );
    }
  } );
} );
