$( () => {
  const $accordionSection = $( '.bs-section--work-at-trackonomy' );
  const headerHeight = $( 'header' ).outerHeight() + 30;
  if ( $accordionSection.length === 0 ) return;

  const scrollAccordion = ( accordionSection ) => {
    accordionSection.each( ( index, element ) => {
      let $wrapper = $( element );
      let accordionItems = $wrapper.find( '.bs-advance-accordion .card-header' );

      accordionItems.each( ( index, element ) => {
        $( element ).on( 'click', ( e ) => {
          let $this = $( e.currentTarget );
          $( 'html,body' ).animate( { scrollTop: $this.offset().top - headerHeight }, 'slow' );
        } );
      } );
    } );
  };

  const init = () => {
    if ( $( window ).width() <= 575 ) {
      scrollAccordion( $accordionSection );
    }
  };

  init();
} );
