$( () => {
  const sectionWithResources = document.getElementsByClassName( 'bs-section--resource-no-image-background' );
  if ( sectionWithResources.length === 0 ) return;
  const sectionWithResourcesArray = Array.from( sectionWithResources );
  sectionWithResourcesArray.forEach( ( element ) => { 
    const resourceNoImage = element.getElementsByClassName( 'bs-post__container--no-image' );
    const resourcesArray = Array.from( resourceNoImage );
    resourcesArray.forEach( ( element, index ) => {
      if ( index % 2 === 0 ) {
        element.classList.add( 'bs-post__container--black' );
      } else {
        element.classList.add( 'bs-post__container--grey' );
      }
    } );
  } );
} );