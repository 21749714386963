/* global debounce */
$( $ => {
  const accordionWrapper = $( '.bs-section--home-breakthrough-platform .bs-advance-accordion' );
  if ( accordionWrapper.length === 0 ) return;

  const header = $( 'header' );
  const attributes = accordionWrapper.data( 'attributes' );
  const breakpoint = attributes.colBreakpoint;

  accordionWrapper.find( '.bs-advance-accordion__right-container .card-header.accordion__block__btn' ).on(
    'click',
    debounce( e => {
      setTimeout( () => {
        const content = $( e.target ).closest( '.card-header' ).next();
        const card = content.parent( '.card' );
        const oldCard = card.parent().find( '.card.custom-active' );
        oldCard.removeClass( 'custom-active' );
        card.addClass( 'custom-active' );

        if ( $( window ).width() < breakpoint && card.hasClass( 'active' ) ) {
          const prevCard = card.prev();
          let scrollValue;
          scrollValue = card.offset().top - header.height();
          if ( prevCard.length !== 0 && oldCard.index() < card.index() ) {
            scrollValue -= oldCard.height();
            console.log( oldCard.find( '.card-header' ).outerHeight() );
            scrollValue += oldCard.find( '.card-header' ).outerHeight();
          }
          scrollValue -= 10;
          window.scrollTo( { top: scrollValue, behavior: 'smooth'} );
        }
      }, 500 ); // 500ms delay after the click event
    }, 105 )
  );

  accordionWrapper.find( '.bs-advance-accordion__right-container>div .card.active' ).addClass( 'custom-active' );
} );
