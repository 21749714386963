$( $ => {
  const repeatSection = $( '.bs-section--careers-life-at-trackonomy .bs-div--careers-life-at-inner' );
  const rsParent = repeatSection.parent();
  const clone = rsParent.html();

  rsParent.append( clone );
  rsParent.append( clone );
  rsParent.append( clone );
  $( '.bs-section--careers-life-at-trackonomy .bs-div--careers-life-at-inner' ).addClass( 'initialized' );
} );
