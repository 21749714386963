/* global debounce */
import { LottieAnimator } from '../../../../../plugins/berg/src/block/lottie-animator/js/core-event-handler';

$.fn.lottieSequence = function ( options ) {
  const settings = $.extend(
    {
      sequenceInterval: 0,
      circular: false,
    },
    options
  );
  const lottieQueue = [];
  const bottomRootMargin = $( window ).height() > $( window ).width() ? '-10%' : '-20%';
  const observerOptions = {
    root: null,
    rootMargin: '0px 0px ' + bottomRootMargin + ' 0px',
  };
  const addLottieToQueue = lottie => {
    let no = $( lottie ).data( 'lottie-sequence' );
    if ( no === undefined ) {
      const animator = new LottieAnimator( lottie );
      animator.init();
      no = lottieQueue.length;
      $( lottie ).attr( 'data-lottie-sequence', no );
      lottieQueue.push( animator );
    }
    return lottieQueue[no];
  };
  const initiateLotties = lotties => {
    lotties.each( ( i, lottie ) => addLottieToQueue( lottie ) );
  };
  const animateLotties = lotties => {
    const animatorChain = [];
    const animateLottie = chainObject => {
      if ( !chainObject ) {
        return;
      }
      const lottieAnimation = chainObject.animator.lottieAnimation;
      lottieAnimation.goToAndStop( 0, true );
      lottieAnimation.play();
      lottieAnimation.addEventListener(
        'complete',
        ( () => {
          const nextAnimator = debounce( () => {
            animateLottie( chainObject.next );
          }, settings.sequenceInterval );
          lottieAnimation.removeEventListener( 'complete' );
          return nextAnimator;
        } )()
      );
    };
    lotties.each( ( i, lottie ) => {
      const chainObject = {
        animator: addLottieToQueue( lottie ),
      };
      if ( i > 0 ) {
        animatorChain[i - 1].next = chainObject;
      }
      animatorChain.push( chainObject );
    } );
    if ( animatorChain.length === 0 ) return;
    if ( settings.circular ) {
      animatorChain[animatorChain.length - 1].next = animatorChain[0];
    }
    animateLottie( animatorChain[0] );
  };
  $( this ).each( ( i, element ) => {
    const $element = $( element );
    const lotties = $element.find( '.bs-lottie-animator' );
    initiateLotties( lotties );
    const observer = new IntersectionObserver( entries => {
      if ( entries[0].isIntersecting ) {
        animateLotties( lotties );
        observer.disconnect();
      }
    }, observerOptions );
    observer.observe( element );
  } );
};